import React from 'react';
import PropTypes from 'prop-types';
import {
  AreaChart, Area, Label, ReferenceLine, ReferenceDot,
} from 'recharts';
import { FragmentTextureColors } from '../../../assets/static/models';
import './intervalStyling.css';

class IntervalChart extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const {
      intervalList,
      onAreaClick,
      editIndex,
    } = this.props;

    // If we don't have data, don't render. Easy.
    if (!intervalList || intervalList.length < 1) return (<div />);

    /**
     * The data we have is constructed as a single object rather than array of them.
     * This is the only way that stacked bar charts are supported.
     * So, we're feeding the BarChart a list with one object and a bunch of index-like keys.
     */
    const data = {};
    const refs = [];
    const refsTop = [];
    intervalList.forEach((val, idx) => {
      data[idx] = val.value;
      refs.push(val.top);
      refs.push(val.bottom);
      refsTop.push({ val: ((val.bottom - val.top) / 2) + val.top, texture: val.texture });
    });

    const areas = intervalList.map((item, idx) => {
      // Get the color assigned to this texture.
      let fillColor = FragmentTextureColors[item.texture];
      // this.props.type === 'usc' ? fillColor=USCTextureColors[item.texture] :
      // fillColor=FragmentTextureColors[item.texture];

      // If it doesn't exist, generate a random one.
      if (!fillColor) fillColor = `#${Math.floor(Math.random() * 16777215).toString(16)}`;

      const idkey = Math.random();
      // Create the area.
      return (
        <Area
          key={idkey}
          name={item.texture}
          dataKey={idx}
          fill={fillColor}
          stoke={fillColor}
          dot={false}
          baseLine={8}
          isAnimationActive={false}
          onClick={() => onAreaClick(item)}
        />
      );
    });

    const refLines = refs.map((val, idx) => {
      const idkey = Math.random();
      const labelText = `${0 - val} m`;
      return (
        <ReferenceLine
          key={idkey}
          y={val}
          stokeWidth={editIndex > -1 && idx === editIndex ? 20 : 2}
          stroke={editIndex > -1 && idx === editIndex ? 'yellow' : 'red'}
        >
          <Label value={labelText} fill="red" fontSize={16} position="bottom" />
        </ReferenceLine>
      );
    });

    const refTextureLines = refsTop.map((val) => {
      const idkey = Math.random();
      return (
        <ReferenceDot key={idkey} x={0} y={val.val} r={0.1} strokeWidth={0} ifOverflow="extendDomain">
          <Label value={val.texture} fill="red" fontSize={12} position="right" />
        </ReferenceDot>
      );
    });

    return (
      <div>
        {/*   Have to duplicate data in order to fill the colour of the intervals */}
        <AreaChart
          width={80}
          height={1000}
          data={[data, data]}
          margin={{
            top: 0, right: 0, bottom: 20, left: 0,
          }}
        >
          {areas}
          {refLines}
          {refTextureLines}
        </AreaChart>
      </div>
    );
  }
}

IntervalChart.propTypes = {
  intervalList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onAreaClick: PropTypes.func.isRequired,
  editIndex: PropTypes.number.isRequired,
};

export default IntervalChart;
