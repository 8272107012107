/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import {
  Button, Row, Col, FormGroup,
} from 'react-bootstrap';
import { PropTypes } from 'prop-types';

import FieldSamples from './FieldSamples';
import ReviewIntervalChart from '../site-review/ReviewIntervalChart';
import useWebService from '../../../hooks/useWebService';
import {
  getSubmissionAndData,
  getPageSubmissionSites,
} from '../../PreviousSubmissions/queries';
import ConvertFromSubmitObjToState from '../../../logic/ConvertFromSubmitObjToState';

function BoreholePagination({
  boreholes,
  initialize,
  siteName,
  tests,
  setSampleTest,
  testsForSamples,
}) {
  const [selectedBorehole, setSelectedBorehole] = useState(boreholes[0]);
  const [filteredBoreholes, setFilteredBoreholes] = useState([]);
  const [previousSubmissions, setPreviousSubmissions] = useState([]);

  const { getGqlClient } = useWebService();
  const gqlClient = getGqlClient();

  const handleBoreholeChange = (event) => {
    const index = filteredBoreholes.findIndex(
      (borehole) => borehole.name === event.target.value,
    );
    if (index >= 0) {
      setSelectedBorehole(filteredBoreholes[index]);
    }
  };

  const handlePrevious = () => {
    const index = filteredBoreholes.findIndex(
      (borehole) => borehole.name === selectedBorehole.name,
    );
    if (index > 0) {
      setSelectedBorehole(filteredBoreholes[index - 1]);
    }
  };

  const handleNext = () => {
    const index = filteredBoreholes.findIndex(
      (borehole) => borehole.name === selectedBorehole.name,
    );
    if (index < filteredBoreholes.length - 1) {
      setSelectedBorehole(filteredBoreholes[index + 1]);
    }
  };

  const handleDisablePrevious = () => {
    const index = filteredBoreholes.findIndex(
      (borehole) => borehole.name === selectedBorehole.name,
    );
    return index === 0;
  };

  const handleDisableNext = () => {
    const index = filteredBoreholes.findIndex(
      (borehole) => borehole.name === selectedBorehole.name,
    );
    return index === filteredBoreholes.length - 1;
  };

  const getMaxDepthScale = () => {
    let maxDepthScale = 0;
    boreholes.forEach((item) => {
      const depths = item.intervals.map((interval) => interval.interval_bottom);
      const maxDepth = Math.max(...depths);

      if (maxDepth > maxDepthScale) {
        maxDepthScale = maxDepth;
      }
    });
    return maxDepthScale;
  };

  const editSubmittedEntity = (submission) => {
    const editObj = {
      ...ConvertFromSubmitObjToState(submission, false),
      isSubmissionEdit: true,
      id: submission.submission.id,
      _id: `${submission.submission.name}__${new Date().toISOString()}`,
    };
    initialize(editObj);
  };

  // Filter the boreholes, returning the most updated ones in case of duplicates
  // (editing without submission)
  const filterBoreholes = () => {
    const filteredBoreholesLocal = boreholes.filter((borehole) => {
      let outdatedBoreholes = [];

      const sameBoreholes = boreholes.filter(
        (element) => element.name === borehole.name
          && element.siteid === borehole.siteid
          // eslint-disable-next-line no-underscore-dangle
          && element._id !== borehole._id,
      );

      if (sameBoreholes.length > 0) {
        outdatedBoreholes = sameBoreholes.filter(
          (element) => element.edit_time > borehole.edit_time,
        );
      }

      if (outdatedBoreholes.length === 0) return true;
      return false;
    });

    return filteredBoreholesLocal;
  };

  useEffect(() => {
    const fetchPrevSubsAndUpdateState = async () => {
      const sites = await gqlClient.query(
        getPageSubmissionSites,
        {
          sortBy: 'alphabetical',
          sortDirection: 'down',
          searchValue: '',
          currentPage: 0,
          pageSize: 5,
        },
        { fetchPolicy: 'no-cache' },
      );

      const fetchedPreviousSubmissions = await gqlClient.query(
        getSubmissionAndData,
        {
          siteIds: sites.data
            ? sites.data.getSubmissionSites.map((sub) => sub.id)
            : [],
          isAdmin: false,
        },
        { fetchPolicy: 'no-cache' },
      );

      const prevSub = (await fetchedPreviousSubmissions?.data?.getSubmissionAndData) || [];
      setPreviousSubmissions(prevSub);
    };
    fetchPrevSubsAndUpdateState();

    const filteredBoreholesLocal = filterBoreholes();
    setFilteredBoreholes(filteredBoreholesLocal);
  }, []);

  useEffect(() => {
    if (previousSubmissions && previousSubmissions.length > 0) {
      const selectedSubmission = previousSubmissions.find(
        (sub) => sub.sampleLocation.sampleLocName === selectedBorehole.name
          && sub.site.name === siteName,
      );
      if (selectedSubmission) editSubmittedEntity(selectedSubmission);
    }
  }, [siteName, previousSubmissions]);

  return (
    <>
      <Row>
        <Col md={4}>
          <FormGroup>
            <Select
              labelId="borehole-pagination-select"
              id="borehole-pagination-select"
              label="Sample Location"
              className="boreholePaginationSelect"
              value={selectedBorehole.name}
              onChange={(event) => handleBoreholeChange(event.target.value)}
            >
              <MenuItem value="">SELECT</MenuItem>
              {filteredBoreholes.map((borehole) => (
                <MenuItem key={borehole.name} value={borehole.name}>
                  {borehole.name}
                </MenuItem>
              ))}
            </Select>
          </FormGroup>
        </Col>
        <Col sm={4} md={3}>
          <Button
            className="boreholePaginationPrev"
            block
            onClick={handlePrevious}
            disabled={handleDisablePrevious()}
          >
            &lt;&lt; Previous
          </Button>
        </Col>
        <Col sm={4} md={2}>
          <div style={{ textAlign: 'center' }}>
            <h4>{selectedBorehole.name}</h4>
          </div>
        </Col>
        <Col sm={4} md={3}>
          <Button
            className="boreholePaginationNext"
            block
            onClick={handleNext}
            disabled={handleDisableNext()}
          >
            Next &gt;&gt;
          </Button>
        </Col>
      </Row>
      <Row>
        <Col sm={2} md={1}>
          {filteredBoreholes.map((borehole) => {
            if (borehole.name === selectedBorehole.name) {
              return (
                <div key={borehole.name}>
                  <ReviewIntervalChart
                    // eslint-disable-next-line no-underscore-dangle
                    key={borehole._id}
                    maxDepth={-(getMaxDepthScale())}
                    intervalList={borehole.intervals.map((item) => ({
                      top: -parseFloat(item.intervalTop),
                      bottom: -parseFloat(item.intervalBottom),
                      texture: item.fragment_texture,
                      value: [
                        -parseFloat(item.intervalTop),
                        -parseFloat(item.intervalBottom),
                      ],
                    }))}
                  />
                </div>
              );
            }
            // eslint-disable-next-line no-underscore-dangle
            return <div key={borehole._id} />;
          })}
        </Col>
        <Col sm={10} md={11} style={{ marginTop: '10px' }}>
          <FieldSamples
            fieldSampleList={selectedBorehole.sample_collection}
            tests={tests}
            borehole={selectedBorehole}
            setSampleTest={setSampleTest}
            testsForSamples={testsForSamples}
          />
        </Col>
      </Row>
    </>
  );
}

BoreholePagination.propTypes = {
  boreholes: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  siteName: PropTypes.string.isRequired,
  initialize: PropTypes.func.isRequired,
  tests: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  setSampleTest: PropTypes.func.isRequired,
  testsForSamples: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default BoreholePagination;
